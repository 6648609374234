const styles = {
    mainContainer: {
        flex: 1,
        // backgroundColor: 'white',
    },
    darktoolbar: {
        backgroundColor: '#17294E',
    }

}

export default styles;
